import React, {Fragment, useEffect, useState} from 'react'
import {Card, Col, Container, Form, Row, Button, Spinner} from 'react-bootstrap';
import {Server} from "../../constants";
import axios from "axios";
import Swal from "sweetalert2";
import cuid from "cuid";
import {verificarCedula, verificarRuc} from 'udv-ec';

export const ModalContacto = (props) => {

    const [listaModelos, setListaModelos] = useState([]);
    const [listaVersiones, setListaVersiones] = useState([]);
    const [listaCiudades, setListaCiudades] = useState([]);
    const [listaConcesionarios, setListaConcesionarios] = useState([]);
    const [listaInteresados, setListaInteresados] = useState([]);


    const [nombre, setNombre] = useState("");
    const [apellido, setApellido] = useState("");
    const [telefono, setTelefono] = useState("");
    const [email, setEmail] = useState("");
    const [direccion, setDireccion] = useState("");
    const [identificacion, setIdentificacion] = useState("");
    const [ciudad, setCiudad] = useState(0);
    const [consecionario, setConsecionario] = useState(0);
    const [tipoInteresado, setTipoInteresado] = useState(0);
    //Campos añadidos
    const [modeloSGCId, setModeloSGCId] = useState(0);
    const [tipoDocumento, setTipoDocumento] = useState("");
    const [tipoVehiculo, setTipoVehiculo] = useState("");
    const [agenciaIdSGC, setAgenciaIdSGC] = useState("");
    const [tipoVehiculoSgc, setTipoVehiculoSGC] = useState(0);

    //Adicionales
    const [empresa, setEmpresa] = useState("");
    const [almacen, setAlmacen] = useState("");
    const [modelo, setModelo] = useState(0);
    const [modeloNombre, setModeloNombre] = useState("");
    const [version, setVersion] = useState(0);
    const [vehiculoColor, setVehiculoColor] = useState("");
    const [anio, setAnio] = useState("");
    const [precio, setPrecio] = useState("");
    // const [entrada, setEntrada] = useState("");
    // const [cuotas, setCuotas] = useState("");
    // const [valorCuota, setValorCuota] = useState("");
    const [versionNombre, setVersionNombre] = useState("");
    const [colorNombre] = useState("");
    const [ciudadNombre, setCiudadNombre] = useState("");
    const [concesionarioNombre, setConcesionarioNombre] = useState("");
    // const [contactoNombre, setContactoNombre] = useState("");
    // const [contactoEmail, setContactoEmail] = useState("");
    // const [contactoTelefono, setContactoTelefono] = useState("");
    const [concesionarioDireccion, setConcesionarioDireccion] = useState("");
    const [concesionarioTelefono, setConcesionarioTelefono] = useState("");
    const [concesionarioHorario, setConcesionarioHorario] = useState("");
    const [concesionarioHorarioFs, setConcesionarioHorarioFs] = useState("");
    const [almacenMapa, setAlmacenMapa] = useState("");

    const emailRegex = new RegExp(
        "^(?=.{1,64}@)[A-Za-z0-9_-]+(\\.[A-Za-z0-9_-]+)*@[^-][A-Za-z0-9-]+(\\.[A-Za-z0-9-]+)*(\\.[A-Za-z]{2,})$"
    );
    const celularRegex = new RegExp("[0]{1}[8-9]{1}[0-9]{8}");
    const letrasRegex = new RegExp("^[ A-Za-z]+$");
    const soloNumerosRegex = new RegExp("^[ 0-9]+$")

    //Spinner
    const [activeSpinner, setActiveSpinner] = useState(false);

    useEffect(() => {
        obtenerModelos();
        obtenerTipoInteresados();

        var campoTelefono = document.getElementById('telefono');
        campoTelefono.maxLength = 10;
    }, [])

    const obtenerModelos = async () => {
        const url = `${Server}/api/modelos_landing_call/1/1-2/2`
        const resultado = await axios.get(url);
        setListaModelos(resultado.data)

    }

    const leerModelo = async (event) => {
        var params = event.target.value.split('|')

        if (parseInt(params[0]) === 1) {
            setTipoVehiculoSGC("VEHICULO LIVIANO");
        } else {
            setTipoVehiculoSGC("VEHICULO PESADO");
        }
        await setTipoVehiculo(params[0]);
        await setModelo(params[1]);
        await setModeloNombre(params[2]);

        obtenerVersiones(params[1]);
        obtenerCiudades(params[0]);

        setListaCiudades([]);
        setConsecionario(0);
        setListaConcesionarios([]);
        setVersion(0);
        setCiudad(0);
        setConsecionario(0);
    }

    const leerVersion = async (event) => {
        var params = event.target.value.split('|')

        await setVehiculoColor(params[0]);
        await setAnio(params[1]);
        await setPrecio(params[3]);
        await setVersion(params[5]);
        await setModeloSGCId(params[6]);
        await setVersionNombre(params[7]);
    }

    const obtenerVersiones = async (modelo) => {
        const url = `${Server}/api/versiones_landing/1/${modelo}`
        await axios.get(url).then(response => {
            setListaVersiones(response.data)
        })
            .catch(e => {
                console.log(e);
            });

    }

    const leerTipoDocumento = async (event) => {
        setTipoDocumento(event.target.value)

        var campoIdentificacion = document.getElementById("identificacion")
        campoIdentificacion.disabled = false;
        campoIdentificacion.value = ""

        if (event.target.value === "CEDULA") {
            campoIdentificacion.maxLength = 10;
        } else {
            campoIdentificacion.maxLength = 13;
        }
    }

    const obtenerCiudades = async (tipo_vehiculo) => {

        var url = "";
        url = `${Server}/api/ciudades/1/${tipo_vehiculo}-3/2`
        const resultado = await axios.get(url);
        setListaCiudades(resultado.data);
    }

    const obtenerTipoInteresados = async () => {
        const url = `${Server}/api/interesados_landing/TI/CALLCENTER`
        const resultado = await axios.get(url);
        setListaInteresados(resultado.data)
    }

    const leerCiudad = async (event) => {
        let params = event.target.value.split('|')
        obtenerConcecionarios(params[0]);
        setCiudad(params[0]);
        setCiudadNombre(params[1]);

    }

    const leerConcesionario = (event) => {
        let params = event.target.value.split('|')
        setEmpresa(params[3])
        setAlmacen(params[0])
        setConcesionarioNombre(params[7]);
        setConcesionarioDireccion(params[2]);
        setConcesionarioTelefono(params[8]);
        setConcesionarioHorario(params[4]);
        setConcesionarioHorarioFs(params[5]);
        setAlmacenMapa(params[6]);
        setAgenciaIdSGC(params[1])

        setConsecionario(event.target.value)

    }

    const obtenerConcecionarios = async (ciudad) => {

        let url = ""
        url = `${Server}/api/concesionarios/1/${tipoVehiculo}-3/2/${ciudad}`
        const resultado = await axios.get(url);
        setListaConcesionarios(resultado.data);

    }

    const leerTipoInteresado = async (event) => {
        setTipoInteresado(event.target.value);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (camposValidos()) {
            ingresarSimulador();
        }
    }


    const ingresarSimulador = async () => {
        let cid = cuid();
        setActiveSpinner(true);

        const dataForm = new FormData();
        dataForm.append("id", cid);
        dataForm.append("ciudad", ciudad);
        dataForm.append("empresa", empresa);
        dataForm.append("almacen", almacen);
        dataForm.append("cedula", identificacion);
        dataForm.append("nombre", nombre);
        dataForm.append("apellido", apellido);
        dataForm.append("email", email);
        dataForm.append("direccion", direccion);
        dataForm.append("telefono", telefono);
        dataForm.append("token", '47x7j5hcm1');
        dataForm.append("tipo_vehiculo", tipoVehiculo);
        dataForm.append("vehiculo_modelo", modelo);
        dataForm.append("vehiculo_modelo_nombre", modeloNombre);
        dataForm.append("vehiculo_version", version);
        dataForm.append("vehiculo_color", vehiculoColor);
        dataForm.append("vehiculo_anio", anio);
        dataForm.append("vehiculo_precio", precio);
        dataForm.append("forma_pago", "contado");
        dataForm.append("entrada", 0);
        dataForm.append("plazo", 0);
        dataForm.append("cuota", 0);
        dataForm.append("version_nombre", versionNombre);
        dataForm.append("color_nombre", colorNombre);
        dataForm.append("ciudad_nombre", ciudadNombre);
        dataForm.append("concesionario_nombre", concesionarioNombre);
        dataForm.append("contacto_nombre", '');
        dataForm.append("contacto_email", '');
        dataForm.append("contacto_telefono", '');
        dataForm.append("concesionario_direccion", concesionarioDireccion);
        dataForm.append("concesionario_telefono", concesionarioTelefono);
        dataForm.append("concesionario_horario", concesionarioHorario);
        dataForm.append("concesionario_horariofs", concesionarioHorarioFs);
        dataForm.append("concesionario_mapa_url", almacenMapa);
        //Campos Extras
        dataForm.append("tipo_documento", tipoDocumento);
        dataForm.append("agencia_id_sgc", agenciaIdSGC);
        dataForm.append("modelocomercial_id_sgc", modeloSGCId);
        dataForm.append("tipo_vehiculo_sgc", tipoVehiculoSgc);
        dataForm.append("tipo_interesado_sgc", tipoInteresado);

        var json = [
            {
                "bdt1": true,
                "ddt2": "",
                "dt1": "Juan",
                "dt3": "Castro",
                "dt5": "0989307015",
                "dt6": "jcastro@hotmail.com",
                "dt10": "1713627071",
                "dt7": "",
                "dt12": "WEB",
                "dt13": "VEHICULO LIVIANO",
                "dt14": "NUEVO",
                "dt15": "CEDULA",
                "dt16": "ECUADOR",
                "dt17": "NATURAL",
                "dt18": "HYUNDAI",
                "dt19": "SGC",
                "dt21": "",
                "ndt2": 11,
                "ndt6": "46",
                "ndt11": "41",
                "ndt13": "",
                "dt23": "0",
                "ndt15": "43990",
                "dt29": "cl1y5co2200042865wmtifpe6",
                "dt30": "Org\u00e1nico",
                "dt31": "MARCA",
                "dt32": "ORGANICO",
                "dt33": "981ca38f59"
            },
            {
                "bdt1": true,
                "ddt2": "",
                "dt1": "Juan",
                "dt3": "Castro",
                "dt5": "0989307015",
                "dt6": "jcastro@hotmail.com",
                "dt10": "1713627071",
                "dt7": "",
                "dt12": "WEB",
                "dt13": "VEHICULO LIVIANO",
                "dt14": "NUEVO",
                "dt15": "CEDULA",
                "dt16": "ECUADOR",
                "dt17": "NATURAL",
                "dt18": "HYUNDAI",
                "dt19": "SGC",
                "dt21": "",
                "ndt6": "40",
                "ndt11": "34",
                "ndt13": "",
                "dt23": "0",
                "ndt15": "25990",
                "dt29": "cl1y5u85n000528652h5e8yhl",
                "ndt2": 11,
                "dt30": "Org\u00e1nico",
                "dt31": "MARCA",
                "dt32": "ORGANICO",
                "dt33": "981ca38f59"
            },
            {
                "bdt1": true,
                "ddt2": "",
                "dt1": "Juan",
                "dt3": "Castro",
                "dt5": "0989307015",
                "dt6": "jcastro@hotmail.com",
                "dt10": "1713627071",
                "dt7": "",
                "dt12": "WEB",
                "dt13": "VEHICULO LIVIANO",
                "dt14": "NUEVO",
                "dt15": "CEDULA",
                "dt16": "ECUADOR",
                "dt17": "NATURAL",
                "dt18": "HYUNDAI",
                "dt19": "SGC",
                "dt21": "",
                "ndt6": "16",
                "ndt11": "11",
                "ndt13": "",
                "dt23": "0",
                "ndt15": "36790",
                "dt29": "cl1y6prvk00062865zx79x8m1",
                "ndt2": 11,
                "dt30": "Org\u00e1nico",
                "dt31": "MARCA",
                "dt32": "ORGANICO",
                "dt33": "981ca38f59"
            }
        ]
        //Enviar prospecto
        const url = `${Server}/api/cotizador/ingresar_cotizacion`
        await axios.post(
            url,
            dataForm
        ).then((respuesta) => {
            if (respuesta.data.status === 'Ok') {
                setActiveSpinner(false)
                Swal.fire({
                    title: "ÉXITO",
                    text: "La información fue enviada con éxito",
                    showCloseButton: true,
                    icon: "success",
                });
                setActiveSpinner(false)
                limpiarCampos();
            } else {
                Swal.fire({
                    title: "Información",
                    text: respuesta.data.respuestaSGC.mensaje,
                    showCloseButton: true,
                    icon: "info",
                });
                setActiveSpinner(false)

            }
        }).catch((err) => {
            Swal.fire({
                title: "Error",
                text: "No se pudo enviar la información, intente mas tarde",
                showCloseButton: true,
                icon: "error",
            });
            setActiveSpinner(false)
        })
    }

    const limpiarCampos = () => {
        setModelo(0);
        setModeloSGCId(0);
        setVersion(0);
        setTipoDocumento("");
        setNombre("");
        setApellido("");
        setTelefono("");
        setEmail("");
        setDireccion("");
        setIdentificacion("");
        setTipoVehiculoSGC(0);
        setCiudad(0);
        setConsecionario(0);
        setTipoInteresado(0);
        document.getElementById("identificacion").value = "";
        document.getElementById("identificacion").disabled = true;
        document.getElementById("modelo").selectedIndex = "0";
        document.getElementById("version").selectedIndex = "0";
        document.getElementById("tipo-documento").selectedIndex = "0";
        document.getElementById("ciudad").selectedIndex = "0";
        document.getElementById("concesionario").selectedIndex = "0";
        document.getElementById("origen").selectedIndex = "0";
    }


    const camposValidos = () => {
        if (!emailRegex.test(email)) {
            Swal.fire({
                title: "Error",
                text: "El campo Email no tiene el formato Correcto...",
                showCloseButton: true,
                icon: "error",
            });
            return false;
        }
        if (!letrasRegex.test(nombre) || !letrasRegex.test(apellido)) {
            Swal.fire({
                title: "Error",
                text: "El campo Nombre y Apellido solo acepta caracteres Alfabeticos...",
                showCloseButton: true,
                icon: "error",
            });
            return false;
        }
        if (!soloNumerosRegex.test(telefono)) {
            Swal.fire({
                title: "Error",
                text: "El campo Telefono solo acepta números...",
                showCloseButton: true,
                icon: "error",
            });
            return false;
        }
        if (parseInt(modelo) === 0 || parseInt(version) === 0
        ) {
            Swal.fire({
                title: "Error",
                text: "Verifique los campos Modelo - Version...",
                showCloseButton: true,
                icon: "error",
            });
            return false;
        }
        if (identificacion === ""
        ) {
            Swal.fire({
                title: "Error",
                text: "Verifique el campo Identificación...",
                showCloseButton: true,
                icon: "error",
            });
            return false;
        }
        if (parseInt(consecionario) === 0) {
            Swal.fire({
                title: "Error",
                text: "Verifique los campos Ciudad - Concesionario",
                showCloseButton: true,
                icon: "error",
            });
            return false;
        }

        if (tipoInteresado === 0) {
            Swal.fire({
                title: "Error",
                text: "Verifique el campo Origen...",
                showCloseButton: true,
                icon: "error",
            });
            return false;
        }


        return true;
    }

    const validarIdentificacion = (e) => {
        let identificacion = e.target.value.replace(" ", "");
        let identificacionVálida = false;

        if (tipoDocumento === "CEDULA") {
            identificacionVálida = verificarCedula(identificacion);
        } else {
            identificacionVálida = verificarRuc(identificacion)
        }

        if (!identificacionVálida) {
            Swal.fire({
                position: "top-end",
                icon: "warning",
                title: "El campo identificación es incorrecto",
                showConfirmButton: false,
                timer: 1500,
            });
            setIdentificacion("");
            document.getElementById("identificacion").value = "";
        }

    };


    const validarTelefono = (e) => {
        let telefono = e.target.value.replace(" ", "");

        if (!celularRegex.test(telefono)) {
            Swal.fire({
                title: "Error",
                text: "El campo Teléfono es incorrecto...",
                showCloseButton: true,
                icon: "error",
            });
            setTelefono("");
            document.getElementById("telefono").value = "";
            //document.getElementById("identificacion").focus();
        }
    }


    return (
        <Fragment>
            <Container>
                <Row>
                    <Col md={6}></Col>
                    <Col md={6}>

                        <Card className="text-center">
                            <Card.Header className="titulo-cotizador"
                                         style={{backgroundColor: "#212d58", color: "#ffffff"}}>
                                <span className="letra"> COTIZADOR</span>
                            </Card.Header>
                            <Card.Body>
                                <Form onSubmit={handleSubmit} id="formCotizador">
                                    <Row>
                                        <Col xl={6}>
                                            <Form.Select
                                                id="modelo"
                                                className="mb-3"
                                                aria-label="Default select example"
                                                onChange={leerModelo}
                                                required
                                            >
                                                <option key='blankChoice' hidden value> Modelo</option>
                                                {listaModelos.map(modelo => {
                                                    return <option
                                                        key={modelo.MOD_CODIGO}
                                                        value={modelo.MOD_CATVEHICULO + '|' + modelo.MOD_CODIGO + '|' + modelo.MOD_NOMBRE}
                                                    >
                                                        {modelo.MOD_NOMBRE}</option>
                                                })}
                                            </Form.Select>
                                        </Col>
                                        <Col xl={6}>
                                            <Form.Select
                                                id="version"
                                                className="mb-3"
                                                aria-label="Default select example"
                                                required
                                                onChange={leerVersion}
                                            >
                                                <option key='blankChoice' hidden value> Versión</option>
                                                {listaVersiones.map((version, index) => {
                                                    return <option
                                                        key={version.VER_CODIGO + '-' + index}
                                                        value={
                                                            version.VAC_COLOR + '|' +
                                                            version.VEA_ANIO + '|' +
                                                            version.VEA_BONO + '|' +
                                                            version.VEA_PRECIO_FINAL + '|' +
                                                            version.VEA_PRECIO_PVP + '|' +
                                                            version.VER_CODIGO + '|' +
                                                            version.VER_COD_SGC + '|' +
                                                            version.VER_NOMBRE
                                                        }>{version.VER_NOMBRE}</option>
                                                })}
                                            </Form.Select>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xl={6}>
                                            <Form.Control
                                                id="nombre"
                                                className="mb-3"
                                                type="text"
                                                placeholder="Nombre"
                                                value={nombre}
                                                onChange={(e) => setNombre(e.target.value)}
                                                required
                                            />
                                        </Col>
                                        <Col xl={6}>
                                            <Form.Control
                                                id="apellido"
                                                className="mb-3"
                                                type="text"
                                                placeholder="Apellido"
                                                value={apellido}
                                                onChange={(e) => setApellido(e.target.value)}
                                                required
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xl={6}>
                                            <Form.Control
                                                className="mb-3"
                                                type="text"
                                                id="telefono"
                                                placeholder="Teléfono"
                                                value={telefono}
                                                onChange={(e) => setTelefono(e.target.value)}
                                                required
                                                onBlur={validarTelefono}

                                            />
                                        </Col>
                                        <Col xl={6}>
                                            <Form.Control
                                                id="email"
                                                className="mb-3"
                                                type="email"
                                                placeholder="Email"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                                required
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xl={6}>
                                            <Form.Control
                                                id="direccion"
                                                className="mb-3"
                                                type="text"
                                                placeholder="Dirección"
                                                value={direccion}
                                                onChange={(e) => setDireccion(e.target.value)}
                                                required
                                            />
                                        </Col>
                                        <Col xl={6}>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xl={6}>
                                            <Form.Select
                                                id="tipo-documento"
                                                className="mb-3"
                                                aria-label="Default select example"
                                                onChange={leerTipoDocumento}
                                            >
                                                <option key='blankChoice' hidden value> Tipo de Documento</option>
                                                <option value="CEDULA">Cédula</option>
                                                {/*<option value="RUC">RUC</option>*/}
                                            </Form.Select>
                                        </Col>
                                        <Col xl={6}>
                                            <Form.Control
                                                className="mb-3"
                                                type="text"
                                                placeholder="Identificación"
                                                disabled
                                                id="identificacion"
                                                required
                                                onChange={(e) => setIdentificacion(e.target.value)}
                                                onBlur={validarIdentificacion}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xl={6}>
                                            <Form.Select
                                                id="ciudad"
                                                className="mb-3"
                                                aria-label="Default select example"
                                                onChange={leerCiudad}
                                            >
                                                <option key='blankChoice' hidden value> Ciudad</option>
                                                {listaCiudades.map(ciudad => {
                                                    return <option key={ciudad.CIU_CODIGO}
                                                                   value={ciudad.CIU_CODIGO + '|' + ciudad.CIU_NOMBRE}> {ciudad.CIU_NOMBRE}</option>
                                                })}
                                            </Form.Select>
                                        </Col>
                                        <Col xl={6}>
                                            <Form.Select
                                                id="concesionario"
                                                className="mb-3"
                                                aria-label="Default select example"
                                                onChange={leerConcesionario}
                                            >
                                                <option key='blankChoice' hidden value> Concesionario</option>
                                                {listaConcesionarios.map(objeto => {
                                                    return <option
                                                        key={objeto.ALM_CODIGO + '-' + objeto.ALM_EMPRESA}
                                                        value={
                                                            objeto.ALM_CODIGO + '|' +
                                                            objeto.ALM_COD_SGC + '|' +
                                                            objeto.ALM_DIRECCION + '|' +
                                                            objeto.ALM_EMPRESA + '|' +
                                                            objeto.ALM_HORARIO_LUN_VIER + '|' +
                                                            objeto.ALM_HORARIO_SAB + '|' +
                                                            objeto.ALM_MAPAS_URL + '|' +
                                                            objeto.ALM_NOMBRE + '|' +
                                                            objeto.ALM_TELEFONO + '|' +
                                                            objeto.ALM_TIPO + '|' +
                                                            objeto.EMP_NOMBRE_COMERCIAL
                                                        }>
                                                        {objeto.EMP_NOMBRE_COMERCIAL + '/' + objeto.ALM_NOMBRE}
                                                    </option>
                                                })}
                                            </Form.Select>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xl={6}>
                                            <Form.Select
                                                id="origen"
                                                className="mb-3"
                                                aria-label="Default select example"
                                                onChange={leerTipoInteresado}
                                            >
                                                <option key='blankChoice' hidden value> Origen</option>
                                                {listaInteresados.map(interesado => {
                                                    return <option
                                                        key={interesado.IND_COD_SGC}
                                                        value={interesado.IND_COD_SGC}>
                                                        {interesado.IND_VALOR}</option>
                                                })}
                                            </Form.Select>
                                        </Col>
                                        <Col md={6}>
                                        </Col>
                                    </Row>
                                    {activeSpinner ? (<>
                                                <Spinner animation="border" role="status">

                                                </Spinner>
                                                <p> Enviando Información ...</p>
                                            </>
                                        ) :
                                        (<Button type="submit" style={{
                                            backgroundColor: "#212d58",
                                            color: "#ffffff",
                                            width: "250px"
                                        }}>ENVIAR</Button>)
                                    }
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>

        </Fragment>
    )
}

export default ModalContacto;
