import React from 'react'
import "./Cover.css"
import hyundaiBackground from "../../images/background.jpg"
import { Fragment } from 'react';
import ModalContacto from '../modal-contacto/ModalContacto';


const Cover = () => {
    return (
        <Fragment>
            <div className="cover-container">
                <img className="imagen-fondo" src={hyundaiBackground} alt="fondo" />
                    <ModalContacto/>
                </div>
        </Fragment >


    )
}

export default Cover;