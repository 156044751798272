import './App.css';
import Cover from "./components/cover/Cover"

function App() {
  return (
    <div className="App">
        <Cover></Cover>
    </div>
  );
}

export default App;
